import './HeroTextAndDecorator/style.css';

import { FaFacebookF } from '@react-icons/all-files/fa/FaFacebookF';
import { FaInstagram } from '@react-icons/all-files/fa/FaInstagram';
import { FiMail } from '@react-icons/all-files/fi/FiMail';
import { FiMapPin } from '@react-icons/all-files/fi/FiMapPin';
import { FiPhone } from '@react-icons/all-files/fi/FiPhone';
import React from 'react';

import { IWordpressCTA, IWordpressImage } from '../../types/wordpress/blocks';
import { serializeImageForImageComponent } from '../../utils/serializeImageForImageComponent';
import { tw } from '../../utils/tailwind';
import { Box, Flex, Stack } from '../Core';
import IconWithText from '../IconWithText';
import Image from '../Image';
import StyledLink from '../StyledLink';
import Text from '../Text';
import { TEXT_STYLES } from '../Text/constants';
import ThemedButton from '../ThemedButton';

export type CentreInfos = {
  name: string;
  adresse: string;
  telephone: string;
  email: string;
  facebook: string;
  instagram: string;
};

interface ICentreHeroProps {
  backgroundImage: IWordpressImage | null;
  centreInfos?: CentreInfos;
  heading?: string | null;
  ctas?: IWordpressCTA[];
  kinequickForm?: JSX.Element;
}

const CentreCard: FCWithClassName<{
  centreInfos: ICentreHeroProps['centreInfos'];
  cta: ICentreHeroProps['ctas'][number];
  kinequickForm?: JSX.Element;
}> = ({ centreInfos, cta, kinequickForm }) => (
  <Stack className="space-y-4 relative -mt-10 justify-center items-start bg-secondaryMain max-w-xl w-auto sm:w-80 md:w-72 lg:w-96 min-w-[250px] pr-4 lg:pr-10 pl-3 sm:pl-6 lg:pl-8 pt-16 pb-8 lg:pb-12 xxl:pb-16 text-white">


     <StyledLink
       externalLink
       href={"https://www.google.com/maps/place/" + centreInfos?.adresse}
       className="text-xl text-white lg:text-2xl xxl:text-3xl"
     >
           <IconWithText
           description={centreInfos?.adresse}
           icon={FiMapPin}
           iconClassName="w-5 h-5 sm:w-6 sm:h-6"
           isInline
           textClassName="text-xl font-bold leading-5 !font-heading lg:text-2xl xxl:text-3xl"
           />

     </StyledLink>


    <StyledLink
      shouldObfuscate
      isPhone
      to={centreInfos?.telephone}
      className="text-xl lg:text-2xl xxl:text-3xl"
    >
      <IconWithText
        description={centreInfos?.telephone}
        icon={FiPhone}
        isInline
        iconClassName="w-5 h-5 sm:w-6 sm:h-6"
        textClassName="text-xl font-bold leading-5 !font-heading lg:text-2xl xxl:text-3xl"
      />
    </StyledLink>

    <StyledLink
      shouldObfuscate
      isEmail
      to={centreInfos?.email}
      // fontSize={{ base: 20, lg: 24, xxl: 30 }}
      // color="white"
      className="text-xl lg:text-2xl xxl:text-3xl"
    >
      <IconWithText
        description={centreInfos?.email}
        icon={FiMail}
        isInline
        iconClassName="w-5 h-5 sm:w-6 sm:h-6"
        textClassName="text-xl font-bold leading-5 !font-heading lg:text-2xl xxl:text-3xl"
      />
    </StyledLink>

    <Stack
      isInline
      className="py-4 mt-2 ml-6 space-x-4 text-base xxl:mt-4 sm:ml-8"
    >
      <a href={centreInfos?.facebook} className="border-b-0 text-text">
        <FaFacebookF className="w-5 h-5 sm:h-8 sm:w-8 fill-white" />
      </a>
      <a href={centreInfos?.instagram} className="border-b-0 text-text">
        <FaInstagram className="w-5 h-5 sm:h-8 sm:w-8 fill-white" />
      </a>
    </Stack>

    {kinequickForm && (
      <Box className="sm:!-mb-12 lg:!-mb-24">{kinequickForm}</Box>
    )}

    {/* <StyledLink
      key={cta?.titre}
      to={cta?.internalLink?.uri}
      href={cta?.externalLink ?? undefined}
      anchorId={cta?.anchor}
      state={cta?.navigationProps}
      className="!absolute !-bottom-[30px] !sm:-bottom-[35px]"
    >
      <ThemedButton>{cta?.titre}</ThemedButton>
    </StyledLink> */}
  </Stack>
);

const CentreHero: FCWithClassName<ICentreHeroProps> = (props) => {
  const { backgroundImage, centreInfos, ctas, kinequickForm } = props;
  return (
    <Box className="relative w-full aspect-h-6 aspect-w-4 sm:aspect-w-6 md:aspect-w-9 xl:aspect-w-14">
      <Image
        image={serializeImageForImageComponent({
          image: backgroundImage ?? undefined,
        })}
        loading="eager"
        className="w-full"
      />
      <Flex className={tw('items-center')}>
        <Box className="static w-full px-[5%]">
          <Text
            id="centre-title"
            variant={TEXT_STYLES.HEADLINE}
            style={
              {
                '--strip-color': 'rgb(109 204 222)',
              } as React.CSSProperties
            }
            className="relative z-10 whitespace-nowrap w-fit"
          >
            {centreInfos.name}
          </Text>
          <CentreCard
            centreInfos={centreInfos}
            cta={ctas?.[0]}
            kinequickForm={kinequickForm}
          />
        </Box>
      </Flex>
    </Box>
  );
};

export default CentreHero;
