import { FaMapMarkerAlt } from '@react-icons/all-files/fa/FaMapMarkerAlt';
import GoogleMapReact from 'google-map-react';
import React from 'react';

import { tw } from '../../utils/tailwind';
import { Box } from '../Core';

const defaultProps = {
  center: {
    lat: 50.814427,
    lng: 4.3647,
  },
  zoom: 15,
};

interface IMarkerProps {
  lat: number;
  lng: number;
}

const Marker: FCWithClassName<IMarkerProps> = (props) => {
  const { lat, lng } = props;
  return (
    <FaMapMarkerAlt
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore lat and lng are required by google-map-react but not by react-icons
      lat={lat}
      lng={lng}
      className="absolute -translate-x-1/2 -translate-y-full h-11 md:h-14 w-11 md:w-14 text-primaryMain"
    />
  );
};

interface IGoogleMapProps {
  lat: number;
  lng: number;
  zoom?: number;
}

const GoogleMap: FCWithClassName<IGoogleMapProps> = (props) => {
  const { lat = 51, lng = 4, zoom = 14 } = props;
  const zoomLevel = (zoom ?? defaultProps.zoom) - 1;
  return (
    <Box className={tw('h-[500px] relative w-full', props.className)}>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: process.env.GATSBY_GOOGLE_MAP_API_KEY as string,
        }}
        defaultCenter={{ lat, lng }}
        defaultZoom={zoomLevel}
      >
        <Marker lat={lat} lng={lng} />
      </GoogleMapReact>
    </Box>
  );
};

export default GoogleMap;
