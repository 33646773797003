import { graphql, PageProps } from 'gatsby';
import React, { useEffect } from 'react';
import LazyLoad from 'react-lazyload';

import CentreGallerySection from '../../components/CentreGallerySection';
import { Box, Grid, Stack } from '../../components/Core';
import GoogleMap from '../../components/GoogleMap';
import Hero from '../../components/Hero';
import { HERO_TYPE } from '../../components/Hero/constants';
import ImageCard from '../../components/ImageCard';
import Layout from '../../components/Layout';
import SectionWithTitle from '../../components/SectionWithTitle';
import SEO from '../../components/SEO';
import StyledLink from '../../components/StyledLink';
import TeamMember from '../../components/TeamMember';
import Text from '../../components/Text';
import { TEXT_STYLES } from '../../components/Text/constants';
import ThemedButton from '../../components/ThemedButton';
import { PAGE_BUILDER_BLOC_NAMES } from '../../constants/pages';
import { BUTTON_STYLES } from '../../constants/ui';
import { IWordpressCTA } from '../../types/wordpress/blocks';
import { Mutable } from '../../types/wordpress/node';
import { getLatAndLngFromString } from '../../utils/geolocation';
import { getLocalizedString } from '../../utils/getLocalizedLink';
import shuffle from '../../utils/helpers/shuffle';
import {
  createImageWithArtDirection,
  serializeImageForImageComponent,
} from '../../utils/serializeImageForImageComponent';
import KinequickForm from '../components/Accueil/KinequickForm';

type ICentreProps = PageProps<Queries.CentreQuery, IPageContext>;

const CentreTemplate: FCWithClassName<ICentreProps> = (props) => {
  const { data, pageContext } = props;
  const pageData = data.wpCentre;
  const centre = pageData;
  const pageTemplateData =
    'contenuCentre' in data.wpPage.template
      ? data.wpPage.template.contenuCentre
      : null;
  if (
    !pageData ||
    !pageTemplateData ||
    !pageData?.link ||
    !pageContext.defaultLanguage
  )
    return null;
  useEffect(() => {
    if (pageData.coordonnees?.lienRdv) {
      sessionStorage.setItem(
        'last_viewed_centre_kinequick_id',
        pageData.coordonnees?.lienRdv
      );
    }
  }, []);
  const heroCta: IWordpressCTA | undefined = pageTemplateData?.sectionHeader
    ?.label
    ? {
        fieldGroupName: PAGE_BUILDER_BLOC_NAMES.CTAS,
        internalLink: {
          id: 'kinequick',
          uri: getLocalizedString(
            '/rendez-vous',
            '/en/kinequick',
            pageContext.currentLanguageSlug,
            pageContext.defaultLanguage
          ),
        },
        navigationProps: { idCentre: centre?.coordonnees?.lienRdv },
        style: BUTTON_STYLES.SOLID,
        titre: pageTemplateData?.sectionHeader.label,
      }
    : undefined;
  const staffMembers = centre?.servicesEtStaff?.staff as Mutable<
    NonNullable<
      NonNullable<Queries.CentreQuery['wpCentre']>['servicesEtStaff']
    >['staff']
  >;
  const displayedStaffMembers = shuffle(staffMembers).slice(0, 4);
  const mapCoordinates = getLatAndLngFromString(centre.coordonnees.gps);
  const centreSectionTitle = pageTemplateData?.sectionCarte.titre.replace(
    '{{CENTRE}}',
    centre.title
  );
  const heroImageWithArtDirection = createImageWithArtDirection({
    desktopImage: pageData.featuredImage?.node,
    mobileImage: pageData.coordonnees.imageMobile,
  });
  const heroTemplateData = data.wpPage.hero;
  const heroTitle = heroTemplateData?.titre?.replace(
    '{{CENTRE}}',
    centre.title
  );
  const phraseDaccueilWithLineBreak =
    pageTemplateData?.sectionHeader.phraseDaccueil.replace(
      '{{CENTRE_LONG_NAME}}',
      '\n {{CENTRE_LONG_NAME}}'
    );
  const introSentence = phraseDaccueilWithLineBreak?.replace(
    '{{CENTRE_LONG_NAME}}',
    centre.coordonnees.nomLong
  );

  const frontpageData =
    'contenuHomepage' in data.frontpage.template
      ? data.frontpage.template.contenuHomepage
      : null;

  return (
    <Layout
      currentLanguage={pageContext.currentLanguage}
      currentPath={pageData.link}
      menuData={pageContext.menus}
      siteSettings={pageContext.siteSettings}
      submenuItems={pageContext.submenuItems}
      translations={pageData.translations}
    >
      <SEO pageData={pageData} />
      <Stack className="space-y-gap lg:space-y-gap-xl">
        <Hero
          backgroundImage={heroImageWithArtDirection}
          centreInfos={{ ...centre.coordonnees, name: centre.title }}
          couleurTitre={heroTemplateData?.couleurTitre}
          ctas={heroCta ? [heroCta] : undefined}
          heading={heroTitle}
          type={HERO_TYPE.CENTRE}
          kinequickForm={
            frontpageData?.ctaCarroussel && (
              <KinequickForm
                data={
                  data.configurations?.nodes?.[0]?.configuration.linksForRdv
                }
                cta={frontpageData?.ctaCarroussel[0] || undefined}
                servicePlaceholder="Services"
                centrePlaceholder="Centres"
                defaultCentre={{
                  slug: pageData.slug,
                  title: pageData.title,
                }}
                hideCentre
                lang={pageContext.currentLanguage.code}
              />
            )
          }
        />
        <Stack className="space-y-12">
          <Text
            variant={TEXT_STYLES.H2}
            className="mx-auto text-center whitespace-pre-wrap"
          >
            {introSentence}
          </Text>
          <Text className="text-center mx-auto !text-xl">
            {centre.coordonnees.description}
          </Text>
        </Stack>
        <SectionWithTitle
          heading={pageTemplateData?.sectionServices.titre}
          text={centre.servicesEtStaff.descriptionServices}
        >
          <Grid className="relative gap-8 grid-cols-[repeat(auto-fit,minmax(300px,1fr))] justify-items-center w-full">
            <Box className="absolute -right-[16px] lg:-right-[-20px] -top-[37px] w-[90%] lg:w-4/5 h-[400px] bg-primaryMain" />
            {centre.servicesEtStaff.servicesDisponibles?.map((service) => {
              if (!service) return undefined;
              return (
                <ImageCard
                  key={service.id}
                  color={service.informationsService.couleur}
                  image={serializeImageForImageComponent({
                    image: service.featuredImage?.node,
                  })}
                  text={
                    service.informationsService.description ??
                    service.informationsService.shortDescription
                  }
                  title={service.title}
                  cta={{
                    label: 'En savoir plus',
                    style: BUTTON_STYLES.SOLID,
                    to: service.uri,
                  }}
                  className="max-w-lg"
                  backTextClassName="!text-xl"
                />
              );
            })}
          </Grid>
        </SectionWithTitle>
        <LazyLoad offset={1000} height={1000} once>
          <SectionWithTitle heading={pageTemplateData?.sectionStaff.titre}>
            <Stack className="w-full space-x-20 space-y-20" align="center">
              <Grid className="grid-cols-1 sm:grid-cols-[repeat(auto-fit,minmax(250px,1fr))] gap-x-4 xl:gap-x-12 gap-y-12 justify-items-center w-full">
                {displayedStaffMembers?.map((staffMember) => {
                  if (!staffMember) return undefined;
                  const image = staffMember.staffInformations.photo;
                  return (
                    <TeamMember
                      description={staffMember.staffInformations.description}
                      firstName={staffMember.staffInformations.prenom}
                      gsm={staffMember.staffInformations.gsm}
                      image={serializeImageForImageComponent({ image })}
                      key={staffMember.id}
                      lastName={staffMember.staffInformations.nom}
                      mail={staffMember.staffInformations.email}
                      position={staffMember.staffInformations.poste?.[0]?.title}
                      languages={staffMember.staffInformations.languages}
                      textLink={staffMember.staffInformations.textLink}
                      linkType={staffMember.staffInformations.linkType}
                      externalLink={staffMember.staffInformations.externalLink}
                      phoneLink={staffMember.staffInformations.phoneLink}
                    />
                  );
                })}
              </Grid>
              <StyledLink to={`${props.uri}/staff`}>
                <ThemedButton>
                  {pageTemplateData?.sectionStaff.label}
                </ThemedButton>
              </StyledLink>
            </Stack>
          </SectionWithTitle>
        </LazyLoad>

        <SectionWithTitle heading={centreSectionTitle}>
          <GoogleMap
            lat={mapCoordinates.lat}
            lng={mapCoordinates.lng}
            className="-mt-20 md:-mt-24"
          />
        </SectionWithTitle>

        <CentreGallerySection
          title={pageTemplateData?.sectionGalerie.titre}
          images={centre.photos.images}
        />

        <SectionWithTitle
          heading={pageTemplateData?.sectionRdv.titre}
          text={pageTemplateData?.sectionRdv.description}
          contentAlignment="center"
        >
          <StyledLink
            to={pageTemplateData?.sectionHeader.lienPageKinequick.uri}
            state={{ idCentre: centre.slug }}
          >
            <ThemedButton>{pageTemplateData?.sectionHeader.label}</ThemedButton>
          </StyledLink>
        </SectionWithTitle>
      </Stack>
    </Layout>
  );
};

export default CentreTemplate;

export const query = graphql`
  query Centre($id: String, $currentLanguageSlug: String) {
    wpCentre(id: { eq: $id }) {
      featuredImage {
        node {
          ...MediaItemFragment
          localFile {
            ...HeroImage
          }
        }
      }
      language {
        locale
        slug
      }
      link
      seo {
        ...SEOFragment
      }
      slug
      title
      translations {
        language {
          code
          locale
        }
        uri
      }
      servicesEtStaff {
        descriptionServices
        servicesDisponibles {
          ... on WpService {
            id
            informationsService {
              couleur
              description
              shortDescription
            }
            featuredImage {
              node {
                ...MediaItemFragment
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      width: 360
                      height: 360
                      layout: CONSTRAINED
                    )
                  }
                  publicURL
                }
              }
            }
            title
            uri
            slug
          }
        }
        thematiques {
          ... on WpThematique {
            id
            title
          }
        }
        staff {
          ...TeamMemberFragment
        }
      }
      coordonnees {
        adresse
        email
        facebook
        gps
        instagram
        lienRdv
        telephone
        description
        imageMobile {
          ...MediaItemFragment
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
            publicURL
          }
        }
        nomLong
      }
      photos {
        images {
          ...MediaItemFragment
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1200, layout: CONSTRAINED)
            }
            publicURL
          }
        }
      }
    }
    wpPage(
      template: { templateName: { eq: "Page Centre" } }
      language: { slug: { eq: $currentLanguageSlug } }
    ) {
      hero {
        couleurTitre
        imageMobile {
          ...MediaItemFragment
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 840
                breakpoints: [840]

                layout: CONSTRAINED
              )
            }
            publicURL
          }
        }
        tagline
        titre
      }
      template {
        ... on WpTemplate_PageCentre {
          contenuCentre {
            sectionCarte {
              titre
            }
            sectionGalerie {
              titre
            }
            sectionHeader {
              label
              phraseDaccueil
              lienPageKinequick {
                ... on WpPage {
                  id
                  uri
                }
              }
            }
            sectionRdv {
              titre
              description
            }
            sectionServices {
              couleur
              titre
            }
            sectionStaff {
              label
              titre
            }
          }
        }
      }
    }
    frontpage: wpPage(
      isFrontPage: { eq: true }
      language: { slug: { eq: $currentLanguageSlug } }
    ) {
      template {
        ... on WpTemplate_PageDaccueil {
          contenuHomepage {
            ctaCarroussel {
              ... on WpTemplate_PageDaccueil_Contenuhomepage_CtaCarroussel_Cta {
                anchor
                externalLink
                internalLink {
                  ... on WpPage {
                    id
                    uri
                  }
                }
                style
                titre
              }
            }
          }
        }
      }
    }
    configurations: allWpConfiguration(
      filter: { language: { slug: { eq: $currentLanguageSlug } } }
    ) {
      nodes {
        configuration {
          linksForRdv {
            target
            url
            service {
              ... on WpService {
                title
                slug
              }
            }
            place {
              ... on WpCentre {
                title
                slug
              }
            }
          }
        }
      }
    }
  }
`;
