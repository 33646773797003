import React from 'react';

import { serializeImageForImageComponent } from '../../utils/serializeImageForImageComponent';
import CarouselContainer from '../Carousel/CarouselContainer';
import { Box } from '../Core';
import Image from '../Image';
import SectionWithTitle from '../SectionWithTitle';

interface ICentreGallerySectionProps {
  title: string | undefined;
  images: Queries.CentreQuery['wpCentre']['photos']['images'];
}

const CentreGallerySection: FCWithClassName<ICentreGallerySectionProps> = (
  props
) => {
  const { title, images, ...rest } = props;
  return (
    <SectionWithTitle heading={title} {...rest}>
      <Box className="absolute bg-secondaryMain w-[110%] h-4/5 top-4 md:top-5 left-2/4 -translate-x-1/2 hidden" />
      <CarouselContainer
        interval={50000}
        className="w-full lg:max-w-3xl xl:max-w-6xl mx-auto h-[500px]"
      >
        {images?.map((image) => {
          return (
            <Box key={image.sourceUrl} className="relative h-full">
              <Image
                image={serializeImageForImageComponent({
                  image,
                })}
                objectFit="cover"
                className="h-full"
                disableDrag
              />
            </Box>
          );
        })}
      </CarouselContainer>
    </SectionWithTitle>
  );
};

export default CentreGallerySection;
