import './style.css';

import React, { useContext } from 'react';

import ColorContext from '../../../context/ColorContext';
import { tw } from '../../../utils/tailwind';
import { Flex } from '../../Core';
import Text from '../../Text';
import { TEXT_STYLES } from '../../Text/constants';

interface IHeroTextAndDecoratorProps {
  couleurTitre: string | null;
  tagline?: string | null;
  title?: string | null;
}

const HeroTextAndDecorator: FCWithClassName<IHeroTextAndDecoratorProps> = (
  props
) => {
  const { couleurTitre, title, tagline } = props;
  const { theme } = useContext(ColorContext);
  return (
    <Flex direction="column" className={tw('z-10', props.className)}>
      <Text
        id="hero-title"
        variant={TEXT_STYLES.HEADLINE}
        style={
          {
            '--strip-color':
              theme === 'institute' ? 'rgb(130 16 16)' : 'rgb(109 204 222)',
            color: couleurTitre,
          } as React.CSSProperties
        }
        className={tw(
          'relative max-w-full break-all w-fit',
          tagline ? 'min-h-[1.7em]' : ''
        )}
      >
        {title}
      </Text>
      {tagline && (
        <Flex align="center">
          <Text variant={TEXT_STYLES.TAGLINE} style={{color: couleurTitre}}>{tagline}</Text>
        </Flex>
      )}
    </Flex>
  );
};

export default HeroTextAndDecorator;
