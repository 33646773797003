import React from 'react';

import { IWordpressCTA, IWordpressImage } from '../../types/wordpress/blocks';
import CentreHero from './CentreHero';
import { HERO_TYPE } from './constants';
import StaticHero, { CentreInfos } from './StaticHero';

interface IHeroProps {
  backgroundImage?: IWordpressImage | null;
  centreInfos?: CentreInfos;
  couleurTitre?: string | null | undefined;
  ctas?: IWordpressCTA[];
  heading?: string | null;
  tagline?: string | null;
  type: HERO_TYPE;
  overlay?: boolean;
  kinequickForm?: JSX.Element;
}

const Hero: FCWithClassName<IHeroProps> = (props) => {
  const {
    backgroundImage,
    centreInfos,
    couleurTitre = '#000',
    ctas,
    heading,
    kinequickForm,
    overlay = false,
    tagline,
    type,
    ...rest
  } = props;

  switch (type) {
    case HERO_TYPE.CENTRE:
      return backgroundImage && centreInfos ? (
        <CentreHero
          backgroundImage={backgroundImage}
          ctas={ctas}
          centreInfos={centreInfos}
          heading={heading}
          kinequickForm={kinequickForm}
          {...rest}
        />
      ) : null;
    case HERO_TYPE.STATIC:
      return backgroundImage ? (
        <StaticHero
          backgroundImage={backgroundImage}
          couleurTitre={couleurTitre}
          ctas={ctas}
          heading={heading}
          tagline={tagline ?? undefined}
          overlay={overlay}
          kinequickForm={kinequickForm}
          {...rest}
        />
      ) : null;
    default: {
      const exhaustiveCheck: never = type;
      throw new Error(`There is a case missing in the switch. Unexpected: ${exhaustiveCheck}`);
    }
  }
};

export default Hero;
