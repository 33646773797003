import React, { ChangeEvent, forwardRef, useEffect, useState } from 'react';
import { FieldError } from 'react-hook-form';

import { tw } from '../../utils/tailwind';
import { Box } from '../Core';
import { IQuestion } from '../Newsletter/Newsletter';
import ErrorMessage from './ErrorMessage';
import Label from './Label';

export type Select = Omit<
  IQuestion,
  'required' | 'toggleButtonText' | 'rows'
> & {
  id?: string;
  className?: string;
  errors?: FieldError | FieldError[];
  onBlur?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  options: {
    checked: boolean;
    name: string;
    label: string;
    disabled?: boolean;
  }[];
  type?: Extract<QUESTION_TYPE, 'select'>;
  wrapperClassName?: string;
  defaultValue?: string;
  value?: string;
};

const Select: FCWithClassName<Select> = forwardRef<HTMLSelectElement, Select>(
  (props, ref) => {
    const defaultValue =
      props.options.find((option) => option.checked)?.name ||
      props.defaultValue;

    const [isPlaceholderSelected, setIsPlaceholderSelected] = useState(
      !defaultValue
    );
    const showEmptyDefaultValue = !props.options.some(
      (option) => option.checked
    );
    const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
      const selectedIndex = e.currentTarget.selectedIndex;

      setIsPlaceholderSelected(selectedIndex === 0);
      props.onChange(e);
    };

    useEffect(() => {
      setIsPlaceholderSelected(!props.value);
    }, [props.value]);

    return (
      <Box className={props.wrapperClassName}>
        <Label>{props.label}</Label>
        <select
          id={props.id}
          ref={ref}
          onChange={handleChange}
          onBlur={props.onBlur}
          placeholder={props.placeholder}
          className={tw(
            'w-full',
            props.className,
            props.errors ? '!border-red-500' : '',
            isPlaceholderSelected ? 'text-gray-400' : 'text-text'
          )}
          name={props.name}
          defaultValue={props.defaultValue || ''}
          value={isPlaceholderSelected ? '' : props.value || undefined}
        >
          {showEmptyDefaultValue && (
            <option value="" disabled>
              {props.placeholder}
            </option>
          )}
          {props.options.map((option) => (
            <option
              key={option.name}
              value={option.name}
              disabled={option?.disabled ? true : undefined}
            >
              {option.label}
            </option>
          ))}
        </select>
        <ErrorMessage errors={props.errors} />
      </Box>
    );
  }
);

export default Select;
Select.displayName = 'Select';
